import React from 'react';
import Recipes from './components/Recipes';

function App() {
  return (
    <div className="w-120 max-w-full mx-auto p-3">
      <div className="text-center mt-6 mb-3">
        <div className="text-30 font-bold mb-1">Menuu</div>
        <div className="text-20 text-gray-700">
          What are we eating this week?
        </div>
      </div>
      <Recipes />
    </div>
  );
}

export default App;
