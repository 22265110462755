import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import castArray from 'lodash.castarray';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';

interface Recipe {
  id: string;
  name: string;
  ingredients: string[];
}

interface Ingredients {
  [key: string]: string;
}

export default function Recipes() {
  const [data, setData] = useState<{
    recipes: Recipe[];
    ingredients: Ingredients;
  }>({ recipes: [], ingredients: {} });
  const { recipes, ingredients } = data;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      const loadRecords = async () => {
        const result = await fetch('/.netlify/functions/loadRecipes');
        const json = await result.json();
        setData(json);
        setIsLoading(false);
      };

      loadRecords();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [value, setValue] = useState<string[]>([]);
  const options = sortBy([...recipes], 'name').map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const selectedRecipes = compact(
    value.map((v) => recipes.find((r) => r.id === v))
  );

  const selectedIngredientIds = flatten(
    selectedRecipes.map((r) => r.ingredients)
  ).filter(Boolean);

  const ingredientsWithCounts: { [key: string]: number } = {};

  selectedIngredientIds.forEach((id) => {
    const ingredient = ingredients[id];
    if (!ingredient) return;
    if (ingredientsWithCounts[ingredient]) {
      ingredientsWithCounts[ingredient] += 1;
    } else {
      ingredientsWithCounts[ingredient] = 1;
    }
  });

  return (
    <>
      <div className="text-24 mb-5">
        <Select
          options={options}
          value={options.filter((o) => value.includes(o.value))}
          isMulti
          isLoading={isLoading}
          onChange={(v) => {
            if (!v) {
              setValue([]);
            } else {
              const values = castArray(v);
              setValue(values.map((value) => value.value));
            }
          }}
          styles={{
            option: (provided, state) => ({ ...provided, fontSize: '14px' }),
          }}
        />
      </div>

      <div className="text-20 font-bold">Shopping List:</div>

      {!!Object.keys(ingredientsWithCounts).length ? (
        <ul>
          {Object.keys(ingredientsWithCounts)
            .sort()
            .map((name) => (
              <li key={name}>
                {name}

                {ingredientsWithCounts[name] > 1 && (
                  <span className="text-16 text-gray-700">
                    {' '}
                    (x{ingredientsWithCounts[name]})
                  </span>
                )}
              </li>
            ))}
        </ul>
      ) : (
        <div className="text-gray-600">Select some recipes first</div>
      )}
    </>
  );
}
